<template>
  <div>
    <div>
      <el-input
        class="condition_input"
        v-model="keyword"
        placeholder="请输入关键字"
      ></el-input>
      <span class="condition_item">
        <!-- <el-select v-model="organization" placeholder="请选择组织">
          <el-option
            v-for="item in organizationItem"
            :key="item.deptId"
            :label="item.deptName"
            :value="item.deptId"
          >
          </el-option>
        </el-select> -->
        <select-tree
          style="width:168px"
          ref="selectTree"
          :props="props"
          placeholder="请选择组织"
          :options="organizationItem"
          :clearable="true"
          :accordion="true"
          @getValue="getValue($event)"
        />
      </span>
      <span class="condition_item">
        <el-select v-model="role" placeholder="请选择角色">
          <el-option value="">全部</el-option>
          <el-option
            v-for="item in dict.roleList"
            :key="item.roleId"
            :label="item.roleName"
            :value="item.roleId"
          >
          </el-option>
        </el-select>
      </span>
      <span class="condition_item">
        管理员
        <el-select v-model="manageType" placeholder="是否是管理员">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </span>
      <el-button class="buttons query" @click="getUsersLists">查询</el-button>
      <el-button class="buttons import" icon="el-icon-plus" @click="userAdd"
        >添加</el-button
      >
      <el-button
        class="buttons import"
        icon="el-icon-delete"
        @click="deleteUser"
        :disabled="!userList.length"
        >删除</el-button
      >
    </div>
    <div class="table">
      <el-table
        stripe
        :header-cell-style="{ background: '#E8EAEF', color: '#3D3D3D' }"
        ref="multipleTable"
        :data="userDataList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="account" label="账号"> </el-table-column>
        <el-table-column prop="userName" label="用户名"> </el-table-column>
        <el-table-column label="角色名称">
          <template slot-scope="scope">
            {{ scope.row.roleNames }}
          </template>
        </el-table-column>
        <el-table-column prop="deptName" label="组织"> </el-table-column>
        <!-- <el-table-column prop="roleName" label="角色"> </el-table-column> -->
        <el-table-column prop="manageType" label="管理员">
          <template slot-scope="scope">
            {{ scope.row.manageType == 1 ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column prop="telPhone" label="手机号"> </el-table-column>
        <el-table-column prop="phoneNum" label="座机号"> </el-table-column>
        <el-table-column prop="email" label="邮箱"> </el-table-column>
        <el-table-column prop="description" label="备注"> </el-table-column>
        <el-table-column prop="assetCode" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="edit el-icon-edit"
              :disabled="redactForbidden"
              size="small"
              @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <i class="app-operation-line" />
            <el-button
              type="text"
              size="small"
              @click="deleteUser(scope.row, true)"
              style="color: #F56C6C;font-size: 14px"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="app-page-container">
      <span @click="first">首页</span>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="pager"
        :hide-on-single-page="false"
        :page-size="10"
        :current-page="currentPage"
        :total="recordCount"
      >
      </el-pagination>
      <span @click="last">尾页</span>
    </div>
  </div>
</template>

<script>
import { organization, queryDeptAll } from "../../api/AssetManagement";
import {
  getUsersList,
  deleteUsers,
  getDept,
  roleQuUserRole
} from "@/api/systemAdmin/userAdmin.js";

const defaultFormData = {
  type: "添加",
  usrId: ""
};
export default {
  name: "userControl",
  data() {
    return {
      manageType: "",
      keyword: "",
      role: "",
      roleIitem: [],
      organization: "",
      organizationItem: [],
      pages: 1,
      recordCount: 1,
      currentPage: 1,
      redactForbidden: false,
      options: [
        {
          value: "1",
          label: "是"
        },
        {
          value: "2",
          label: "否"
        },
        {
          value: "",
          label: "全部"
        }
      ],
      value: "",
      userDataList: [],
      userList: [],
      dict: {
        roleList: []
      },
      formData: {
        ...defaultFormData
      },
      props: {
        value: "deptId",
        label: "deptName",
        children: "children"
      }
    };
  },
  created() {
    this.getorganization();
    this.getUsersLists();
    this.roleQuUserRole();
  },
  methods: {
    getroleAndFns(value) {
      if (value && value.length) {
        const roleName = [];
        value.forEach(val => {
          if (val.roleName) roleName.push(val.roleName);
        });
        return roleName.join(",");
      }
    },
    getValue(value) {
      this.organization = value;
    },
    //获取组织
    getDepts() {
      getDept({}).then(res => {
        console.log(res);
      });
    },

    //删除
    deleteUser(datas, type) {
      let data = { userIdS: [] };
      if (type) {
        data.userIdS.push(datas.userId);
      }
      for (let i = 0; i < this.userList.length; i++) {
        data.userIdS.push(this.userList[i].userId);
      }
      deleteUsers(data).then(({ data }) => {
        if (data.resultCode === 0) {
          this.$message({
            showClose: true,
            message: "删除成功！",
            type: "success"
          });
          this.getUsersLists();
        } else {
          this.$message({
            showClose: true,
            message: data.errorDesc || "删除失败！",
            type: "error"
          });
        }
      });
    },
    //获取角色列表
    roleQuUserRole() {
      const config = {
        params: {
          userId: localStorage.getItem("userId"),
          page: 1,
          limit: 9999
        }
      };
      roleQuUserRole(config).then(({ data }) => {
        this.dict.roleList = data.data.list;
      });
    },
    //获取用户列表
    getUsersLists() {
      let data = {
        keyword: this.keyword,
        roleId: this.role,
        manageType: this.manageType,
        deptId: this.organization,
        limit: 12,
        curPage: this.pages
      };
      getUsersList(data).then(res => {
        this.userDataList = res.data.data.list;
        this.recordCount = res.data.data.total;
        console.log(res);
      });
    },
    userAdd() {
      this.formData.type = "添加";
      this.$router.push({
        path: "/dashboard/systemSet/Add",
        query: { ...this.formData }
      });
    },
    //获取组织
    getorganization() {
      const config = {
        params: {
          userId: localStorage.getItem("userId")
        }
      };
      queryDeptAll(config).then(({ data }) => {
        const arr = data.data || [];
        arr.forEach(value => {
          value.children = [];
          arr.forEach(val => {
            if (value.deptId === val.parentId) {
              value.children.push(val);
            }
          });
          // if (value.parentId === 0) {
          //   this.organizationItem.push(value);
          // }
          if (value.deptId === parseInt(localStorage.getItem("deptId"), 10)) {
            this.organizationItem.push(value);
          }
        });
      });
    },
    //分页首页
    first() {
      this.currentPage = 1;
      this.pages = 1;
    },
    //分页最后一页
    last() {
      this.currentPage = this.recordCount;
      this.pages = Math.ceil(this.recordCount / 12);
      this.currentPage = this.recordCount;
    },
    //编辑
    handleEdit(row) {
      this.formData.type = "编辑";
      this.formData.usrId = row.userId;
      this.$router.push({
        path: "/dashboard/systemSet/Add",
        query: { ...this.formData }
      });
    },
    //选择框
    handleSelectionChange(val) {
      this.userList = val;
    },
    handleCurrentChange(val) {
      this.pages = val;
    },
    tableRowClassName(row, rowIndex) {
      if (rowIndex == 0) return "headerStyle";
    }
  }
};
</script>

<style scoped>
.edit {
  font-size: 14px;
  color: #6e7790;
}
.edit {
  font-size: 14px;
  color: #6e7790;
}
.edit:hover {
  color: #0cc;
}
.el-icon-edit:hover {
  color: #0cc;
}

.buttons {
  width: 90px;
  margin-left: 10px;
}
.condition_input {
  width: 246px;
}
.condition_item {
  margin-left: 10px;
}
/*表格样式*/
.table {
  margin-top: 20px;
}
/*分页样式*/
.app-page-container {
  margin-top: 20px;
}
.app-page-container .el-pagination {
  display: inline-block;
}
.app-page-container span {
  cursor: pointer;
  color: #616161;
  background: #fff;
  border: 1px solid #e8eaf0;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  transition: 0.1s;
  vertical-align: top;
  border-radius: 16px;
  font-size: 12px;
  width: 56px;
}
.app-page-container span:hover {
  background: #00cccc;
  color: #fff;
  border-color: #00cccc;
}
</style>
